.code-search-input {
    border: 0px;
    border-bottom: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    font-style: italic;
    margin-top: 10px;
    font-weight: 600;
}

.code-preview-heading {
    color: black;
    font-style: italic;
}

.code-preview-container {
    margin: 20px 0px 20px 0px;
}

.code-preview-desc {
    margin: 3px 0px;
}