p {
    margin-bottom: 0px;
}

.frag-sep {
    color: #000 !important;
}

.faded {
    color: #00000066;
}

.faded-with-animation {
    animation: fade 5s;
    animation-fill-mode: forwards;
}

@keyframes fade {
    0% {color: #ffffff}
    75% {color: #00000066}
    100% {color: #000}
}