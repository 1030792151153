.menu-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 20px;
    display: grid;
    grid-template-rows: 50px calc(100% - 50px);
    background-color: white;
    box-sizing: border-box;
    z-index: 1;
}

@media screen and (min-width: 769px) {
    .menu-container {
      width: 60%;
      margin-left: 20%;
    }
  }

.menu-container>*:first-child {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
}

.menu-container>*:nth-child(2) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    box-sizing: border-box;
}

.menu-heading {
    font-size: 22px;
    font-weight: 600;
    text-decoration: underline;
    margin: 20px 0px;
}

.menu-link {
    font-size: 22px;
    margin: 15px 0px;
    color: #2c2c2c;
    text-decoration: none;
}