.app-table {
    width: 100%;
}

.app-table tr {
    height: 40px;
}

.circle-count {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    color: white;
    background-color: black;
    font-size: 12px;
}