.topic-search-input {
    border: 0px;
    border-bottom: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 600;
}

.topic-preview-heading {
    color: black;
}

a {
    text-decoration: none;
}

h4 {
    margin: 0px;
    text-decoration: underline;
}

.topic-preview-container {
    margin: 0px 0px 20px 0px;
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.topic-preview-desc {
    margin: 0px 0px;
    height: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
}