.app-container {
  padding: 15px;
  font-family: 'Baskervville', serif;
  font-size: 1.2em;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5;
}

input {
  font-family: 'Baskervville', serif;
}

.app-header {
  display: grid;
  grid-template-columns: calc(100% - 20px) 20px;
  font-weight: 600;
  width: 100%;
  font-size: 30px;
  height: 70px;
}

.hamburger-menu {
  font-size: 40px;
  line-height: 0.8;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .app-container {
    width: 60%;
    margin-left: 20%;
  }
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: gray;
}

img {
  max-width: 80%;
  max-height: 30em;
  margin: 1em;
}

.unselectable {
  -webkit-user-select: none;     
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}